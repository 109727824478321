import React from "react";
import '../../sass/customButton.css';

const CustomButton = ({ label, link, onClick }) => {
    if (link) {
        return (
            <a href={link} className="customButton" target="_blank" rel="noopener noreferrer">
                {label}
            </a>
        );
    } else {
        return (
            <button onClick={onClick} className="customButton">
                {label}
            </button>
        );
    }
};

export default CustomButton;
