import React from "react";
import '../sass/formula.css';
import CustomH2 from "../components/technicalComponents/CustomH2";
import bolid from '../img/photos/Bolid F4.jpg';
import {useTranslation} from "react-i18next";

const Formula = () => {
    const {t} = useTranslation();

    return(
        <div className="formulaContainer">
            <CustomH2 text="Bolid F4"/>
            <h3>{t("title_bolid_01")}<span>Tatuus</span></h3>
            <h3>{t("title_bolid_02")}<span>Tatuus F4-T014</span></h3>
            <p className="biggerText">{t("title_bolid_03")}</p>
            <div className="tableAndImgContainer">
                <table>
                    <tr>
                        <td><strong>{t("1_1")}</strong></td>
                        <td>{t("2_1")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_2")}</strong></td>
                        <td>{t("2_2")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_3")}</strong></td>
                        <td>{t("2_3")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_4")}</strong></td>
                        <td>{t("2_4")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_5")}</strong></td>
                        <td>{t("2_5")}
                        </td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_6")}</strong></td>
                        <td>{t("2_6")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_7")}</strong></td>
                        <td>{t("2_7")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_8")}</strong></td>
                        <td>{t("2_8")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_9")}</strong></td>
                        <td>{t("2_9")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_10")}</strong></td>
                        <td>{t("2_10")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_11")}</strong></td>
                        <td>{t("2_11")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_12")}</strong></td>
                        <td>{t("2_12")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_13")}</strong></td>
                        <td>{t("2_13")}</td>
                    </tr>
                    <tr>
                        <td><strong>{t("1_14")}</strong></td>
                        <td>{t("2_14")}</td>
                    </tr>
                </table>
                <div className="imagePart">
                    <div className="imageWrapper">
                        <div className="imageContainer">
                            <img src={bolid} alt="Maciej Gładysz's bolid"/>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    );
};

export default Formula;