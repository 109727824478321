import React from "react";
import '../sass/homeIntroduction.css';
import maciejPhoto from '../img/photos/JER_F4WS_NB-4398.jpg';
import CustomButton from "./technicalComponents/CustomButton";
import {useNavigate} from 'react-router-dom';
import {useTranslation} from "react-i18next";

const HomeIntroduction = () => {

    const navigate = useNavigate();

    const {t} = useTranslation();

    const handleClick = () => {
        navigate('/maciej');
    }
    return (<section className="introductionSection">
        <div className="introductionSection-flexItemFirst">
            <h1>Maciej Gładysz</h1>
            <h2>racing driver</h2>
            <p>{t("bio")}</p>
            <div className="buttonContainer">
                <CustomButton label={t("button_01")} onClick={handleClick}/>
            </div>
        </div>
        <div className="introductionSection-flexItemSecond">
            <div className="imageWrapper">
                <div className="imageContainer"><img src={maciejPhoto} alt="Maciej Gładysz"/></div>
            </div>
        </div>
    </section>)
}
export default HomeIntroduction;