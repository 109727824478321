import React from "react";
import '../sass/maciej.css';
import maciejPhoto from '../img/photos/Maciej.jpg';
import CustomButton from "../components/technicalComponents/CustomButton";
import {useTranslation} from "react-i18next";

const Maciej = () => {

    const {t} = useTranslation();

    return(
        <div className="maciejContainer">
            <div className="firstPart">
                <div className="imagePart">
                    <div className="imageWrapper">
                        <div className="imageContainer">
                            <img src={maciejPhoto} alt="Maciej Gładysz"/>
                        </div>
                    </div>
                </div>
                <div className="textPart">
                    <h4 className="firstH4">Maciej Gładysz</h4>
                    <p>{t("content_01")}</p>
                    <p>{t("content_01a")}</p>
                    <p>{t("content_01b")}</p>
                    <p>{t("content_01c")}</p>
                    <p>{t("content_01d")}</p>
                    <p>{t("content_01e")}</p>
                    <h4 className="underlineH4">{t("tittle_01")}</h4>
                    <h4>2024:</h4>
                    <p>{t("content_011")}
                    </p>
                    <p>{t("content_011a")}
                    </p>
                    <h4>2023:</h4>
                    <p>{t("content_02")}
                    </p>
                    <p>{t("content_02a")}
                    </p>
                    <p>{t("content_02b")}
                    </p>
                    <p>{t("content_02c")}
                    </p>
                    <h4>2022:</h4>
                    <p>{t("content_03")}
                    </p>
                    <p>{t("content_03a")}
                    </p>
                    <p>{t("content_03b")}
                    </p>
                    <h4>2021:</h4>
                    <p>{t("content_04")}
                    </p>
                    <h4>2020:</h4>
                    <p>{t("content_05")}
                    </p>
                    <p>{t("content_05a")}
                    </p>
                    <p>{t("content_05b")}
                    </p>
                    <p>{t("content_05c")}
                    </p>
                    <h4>2019:</h4>
                    <p>{t("content_06")}
                    </p>
                    <p>{t("content_06a")}
                    </p>
                    <p>{t("content_06b")}
                    </p>
                    <p>{t("content_06c")}
                    </p>
                    <p>{t("content_06d")}
                    </p>
                    <h4>2018:</h4>
                    <p>{t("content_07")}
                    </p>
                    <p>{t("content_07a")}
                    </p>
                    <h4>2017:</h4>
                    <p>{t("content_08")}
                    </p>
                    <p>{t("content_08a")}
                    </p>
                    <p className="lastP">{t("content_08b")}
                    </p>
                </div>
            </div>
            <div className="secondPart">
                <p>{t("text_01")}
                </p>
                <p>{t("text_02")}
                </p>
                <p>{t("text_03")}
                </p>
                <p>{t("text_04")}
                </p>
                <p>{t("text_05")}
                </p>
                <p>{t("text_07")}
                </p>
                <p>{t("text_08")}
                </p>
                <p>{t("text_09")}
                </p>
                <p>{t("text_10")}
                </p>
                <p>{t("text_11")}
                </p>
                <p>{t("text_12")}
                </p>
                <p>{t("text_13")}
                </p>
                <p>{t("text_14")}
                </p>
                <p>{t("text_15")}
                </p>
            </div>
            <div className="buttonContainer">
                <CustomButton label={t("button_04")} link="https://pl.wikipedia.org/wiki/Maciej_G%C5%82adysz_(kierowca_wy%C5%9Bcigowy)"/>
            </div>
        </div>
    );
};

export default Maciej;