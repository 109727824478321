import React from "react";
import '../sass/homeNews.css';

import CustomH2 from "./technicalComponents/CustomH2";
import CustomArticle from "./technicalComponents/CustomArticle";

import CustomButton from "./technicalComponents/CustomButton";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import article11 from "../img/articles/article11.webp";
import article12 from "../img/articles/article12.webp";
import article13 from "../img/articles/article13.webp";


const HomeNews = () => {


    const {t} = useTranslation();
    const navigate = useNavigate();

    const handleClick = () => {
        navigate('/news');
    }


    return(
        <section className="newsSection">
            <CustomH2 text="News"/>
            <div className="newsSection-grid">
                <CustomArticle
                    date="2024-07-28"
                    title={t("art_13_title")}
                    image={article13}
                    isLarge={true}
                    linkEN="https://mailchi.mp/0f00add48d4b/double-f4-victory-for-maciej-gadysz-in-aragon?e=9eec90125b"
                    linkPL="https://mailchi.mp/47d1093915d9/podwjne-zwycistwo-macieja-gadysza-w-f4-w-aragonii?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-07-25"
                    title={t("art_12_title")}
                    image={article12}
                    isLarge={false}
                    linkEN="https://mailchi.mp/d8e16e14620a/maciej-gadysz-aiming-high-in-f4-in-aragon?e=9eec90125b"
                    linkPL="https://mailchi.mp/7072457b9cd3/maciej-gadysz-po-kolejne-trofea-w-f4-w-aragonii?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-07-07"
                    title={t("art_11_title")}
                    image={article11}
                    isLarge={false}
                    linkEN="https://mailchi.mp/013a4a04bd09/maciej-gadysz-takes-first-formula-4-victory?e=9eec90125b"
                    linkPL="https://mailchi.mp/d16a597a4b8d/maciej-gadysz-wygrywa-w-formule-4?e=9eec90125b"
                />
            </div>
            <div className="buttonContainer">
                <CustomButton label={t("button_01")} onClick={handleClick}/>
            </div>
        </section>
    );
};

export default HomeNews;