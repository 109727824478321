import React from "react";
import '../sass/contact.css';
import CustomH2 from "../components/technicalComponents/CustomH2";

import photo from '../img/photos/JER_F4WS_NB-3738.jpg';
import {useTranslation} from "react-i18next";

const Contact = () => {

    const {t} = useTranslation();
    return(
        <div className="contactContainer">
            <div className="firstPart">
                <CustomH2 text={t("Title2")}/>
                <div className="textWrapper">
                    <div>
                        <p>ADAM GŁADYSZ</p>
                        <p>33-113 Tarnów/Zgłobice (PL)</p>
                        <p>ul. Krakowska 15</p>
                        <p>adam@gladyszracing.pl</p>
                    </div>
                </div>
            </div>
            <div className="secondPart">
                <img src={photo} alt="Maciej gładysz inside a bolid."/>
            </div>

        </div>
    );
};

export default Contact;