import React from "react";
import HomeIntroduction from "../components/HomeIntroduction";
import HomeCarousel from "../components/HomeCarousel";
import Trophies from "../components/technicalComponents/Trophies";
import HomeNews from "../components/HomeNews";
import HomePartners from "../components/HomePartners";

const Home = () => {

    return(
        <div>
            <HomeCarousel/>
            <HomeIntroduction/>
            <Trophies/>
            <HomeNews/>
            <HomePartners/>
        </div>
    );
};
export default Home;