import React from "react";
import '../sass/partnersSection.css';
import CustomH2 from "./technicalComponents/CustomH2";

import orlen from '../img/partners/orlen.svg';
import brukbet from '../img/partners/bruk-bet.svg';
import pzu from '../img/partners/pzu.svg';
import kghm from '../img/partners/kghm.svg';
import maxton from '../img/partners/MaxtonDesign.svg';
import pzm from '../img/partners/pzm.svg';
import pko from '../img/partners/PKO_BANK_POLSKI_CZARNO-BIALY_RGB_31mm.svg';
import {useTranslation} from "react-i18next";


const HomePartners = () => {

    const {t} = useTranslation();

    return(
        <section className="partnersSection">
            <div className="partnersText">
                <CustomH2 text={t("partners")}/>

            </div>
            <div className="partnersSection-container">
                <div className="imgWrapper">
                    <a href="https://www.orlen.pl/pl" target="_blank" rel="noopener noreferrer">
                        <img className="orlen" src={orlen} alt="orlen"/>
                    </a>
                    <a href="https://www.bruk-bet.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="brukbet" src={brukbet} alt="Bruk-Bet"/>
                    </a>
                    <a href="https://www.pzu.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="smallerImg" src={pzu} alt="PZU"/>
                    </a>
                    <a href="https://kghm.com/pl" target="_blank" rel="noopener noreferrer">
                        <img className="kghm" src={kghm} alt="KGHM"/>
                    </a>
                    <a href="https://maxtondesign.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="smallerImg esmallerImg" src={maxton} alt="Maxton Design"/>
                    </a>
                    <a href="https://www.pkobp.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="smallerImg esmallerImg pko" src={pko} alt="PKO Bank Polski"/>
                    </a>
                    <a href="https://pzm.pl/" target="_blank" rel="noopener noreferrer">
                        <img className="smallerImg esmallerImg pzm" src={pzm} alt="PZM"/>
                    </a>
                </div>
            </div>
        </section>
);
};

export default HomePartners;