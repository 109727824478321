import React from 'react';
import '../../sass/customArticle.css';
import { useTranslation } from 'react-i18next';

const CustomArticle = ({date, title, image, isLarge, linkPL, linkEN, isTextSmall}) => {
    const { i18n } = useTranslation();
    const {t} = useTranslation();

    const openLink = () => {
        const url = i18n.language.startsWith('pl') ? linkPL : linkEN;
        window.open(url, '_blank');
    };


    return(
        <article className={`custom-article ${isLarge ? 'large' : ''}`} onClick={openLink}>
            <div className="image-container">
                <img src={image} alt="article media" />
                <div className="info">
                    <div className="infoContainer">
                        <p>{t("articleWywiad")}</p>
                        <p>{date}</p>
                    </div>
                    <h4 className={isTextSmall ? 'small-text' : ''}>{title}</h4>
                </div>
            </div>
        </article>
    );
};

export default CustomArticle;
