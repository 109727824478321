import React from "react";
import '../sass/season.css';
import CustomH2 from "../components/technicalComponents/CustomH2";

import franceFlag from '../img/flag/franceFlag.svg';
import spainFlag from '../img/flag/spainFlag.svg';
import portugalFlag from '../img/flag/portugalFlag.svg';
import f4Spanish from '../img/partners/f4_spanish.png';
import fws from '../img/partners/FWS.svg';
import {useTranslation} from "react-i18next";


const Season = () => {

    const {t} = useTranslation();
    return(
        <div className="seasonContainer">
            <div className="h2Wrapper">
                <CustomH2 text={t("Title")}/>
            </div>
            <div className="contentBox">
                <div className="flexContainer">
                    <img className="eventLogo" src={fws} alt=""/>
                    <p>10-11.02.2024 CIRCUITO DE JEREZ<img src={spainFlag} alt="spain flag"/></p>
                    <p>17-18.02.2024 CIRCUIT RICARDO TORMO<img src={spainFlag} alt="spain flag"/></p>
                    <p>02-03.03.2024 MOTORLAND ARAGON<img src={spainFlag} alt="spain flag"/></p>
                    <p>09-10.03.2024 CIRCUIT DE BARCELONA<img src={spainFlag} alt="spain flag"/></p>
                </div>
                <div className="flexContainer">
                    <img className="eventLogo" src={f4Spanish} alt=""/>
                    <p>10-12.05.2024 CIRCUITO DE MADRID-JARAMA<img src={spainFlag} alt="spain flag"/></p>
                    <p>07-09.06.2024 AUTODROMOW DO ALGARVE<img src={portugalFlag} alt="portugal flag"/></p>
                    <p>05-07.07.2024 CIRCUIT PAUL RICARD<img src={franceFlag} alt="france flag"/></p>
                    <p>26-28.07.2024 MOTORLAND ARAGON<img src={spainFlag} alt="spain flag"/></p>
                    <p>13-15.09.2024 CIRCUIT RICARDO TORMO<img src={spainFlag} alt="spain flag"/></p>
                    <p>04-06.10.2024 CIRCUITO DE JEREZ<img src={spainFlag} alt="spain flag"/></p>
                    <p>08-10.11.2024 CIRCUIT DE BARCELONA<img src={spainFlag} alt="spain flag"/></p>
                </div>
            </div>
            <div className="whiteUnderline"></div>
            <div className="redUnderline"></div>
        </div>
    );
};

export default Season;