import React from "react";
import './sass/app.css';
import {Route, Routes} from "react-router-dom";
import Navbar from "./components/staticComponents/Navbar";
import Footer from "./components/staticComponents/Footer";
import Home from "./pages/Home";
import News from "./pages/News";
import Maciej from "./pages/Maciej";
import Formula from "./pages/Formula";
import Season from "./pages/Season";
import Contact from "./pages/Contact";
import ScrollToTop from "./components/technicalComponents/ScrollToTop";
import Media from "./pages/Media";
import {Helmet} from "react-helmet";
import img from '../src/Untitled-1.png';


function App() {
    return (
        <div className="App">
            <Helmet>
                <title>Official Site - Maciej Gładysz</title>
                <meta name="description" content="Urodziłem się 28 kwietnia 2008 roku w Tarnowie. Pochodzę z rodziny z długimi, motorsportowymi tradycjami. Mój dziadek Janusz rywalizował w rajdach samochodowych, a mój tata Adam to wielokrotny mistrz Polski w wyścigach." />
                <meta property="og:title" content="Official Site - Maciej Gładysz"/>
                <meta property="og:description" content="Urodziłem się 28 kwietnia 2008 roku w Tarnowie. Pochodzę z rodziny z długimi, motorsportowymi tradycjami. Mój dziadek Janusz rywalizował w rajdach samochodowych, a mój tata Adam to wielokrotny mistrz Polski w wyścigach."/>
                <meta property="og:image" content={img}/>
                <meta property="og:type" content="website"/>


            </Helmet>
            <ScrollToTop/>
            <Navbar/>
            <div className="content">
                <Routes>
                    <Route path='/' element={<Home/>}/>
                    <Route path='/news' element={<News/>}/>
                    <Route path='/maciej' element={<Maciej/>}/>
                    <Route path='/formula' element={<Formula/>}/>
                    <Route path='/sezon2024' element={<Season/>}/>
                    <Route path='/kontakt' element={<Contact/>}/>
                    <Route path='/media' element={<Media/>}/>
                </Routes>
            </div>
            <Footer/>
        </div>
    );
}

export default App;
