import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../sass/homeCarousel.css';
import imageFirst from '../img/carusel/JER_F4WS_NB-4121-B.webp';
import imageSecond from '../img/carusel/JER_F4WS_NB-5129.webp';
import imageThird from '../img/carusel/JER_F4WS_NB-7790.webp';
import imageFourth from '../img/carusel/VAL_F4WS_NB-2464.webp';
import imageFifth from '../img/carusel/VAL_F4WS_NB-3032.webp';
import imageSixth from '../img/carusel/VAL_F4WS_NB-7471.webp';
import imageSeventh from '../img/carusel/VAL_F4WS_NB-8361.webp';
import imageEight from '../img/carusel/VAL_F4WS_NB-9433.webp';

import partnerFirst from '../img/partners/MP.svg';
import partnerSecond from '../img/partners/f4_spanish.png';
import partnerThird from '../img/partners/FWS.svg';
import CustomArrow from "./technicalComponents/CustomArrow";

    const MyCarousel = () => {
        const PrevArrow = (props) => (
            <CustomArrow {...props} className="slick-prev" style={{ left: '25px' }} />
        );
        const NextArrow = (props) => (
            <CustomArrow {...props} className="slick-next" style={{ right: '25px' }} />
        );

        const settings = {
            dots: false,
            infinite: true,
            speed: 500,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 4000,
            pauseOnHover: false,
            prevArrow: <PrevArrow />,
            nextArrow: <NextArrow />
        };


    return (
        <div>
            <div className="carouselContainer">
                <Slider {...settings}>
                    <div>
                        <img className="carouselImg" src={imageFirst} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "top"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageSecond} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "bottom"}} />
                    </div>
                    <div>
                        <img className="carouselImg" src={imageThird} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "center"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageFourth} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "center"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageFifth} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "center"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageSixth} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "bottom"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageSeventh} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "bottom"}}/>
                    </div>
                    <div>
                        <img className="carouselImg" src={imageEight} alt="element of a carousel (From Maciej Gładysz album)" style={{objectPosition: "center"}}/>
                    </div>
                </Slider>
                <div className="carousel-logo">
                    <a href="https://www.mpmotorsport.com/" target="_blank" rel="noopener noreferrer">
                        <img src={partnerFirst} alt="MP accelerating talent"/>
                    </a>
                    <a href="https://f4spain.org/f4-espanola/" target="_blank" rel="noopener noreferrer">
                        <img src={partnerSecond} alt="F4 Spanish championship"/>
                    </a>
                    <a href="https://formula-winter-series.com/" target="_blank" rel="noopener noreferrer">
                        <img src={partnerThird} alt="FWS formula winter series"/>
                    </a>
                </div>
            </div>
            <div className="carousel-logoMobile">
                <a href="https://www.mpmotorsport.com/" target="_blank" rel="noopener noreferrer">
                    <img src={partnerFirst} alt="MP accelerating talent"/>
                </a>
                <a href="https://f4spain.org/f4-espanola/" target="_blank" rel="noopener noreferrer">
                    <img src={partnerSecond} alt="F4 Spanish championship"/>
                </a>
                <a href="https://formula-winter-series.com/" target="_blank" rel="noopener noreferrer">
                    <img src={partnerThird} alt="FWS formula winter series"/>
                </a>
            </div>
        </div>
    );
    };

export default MyCarousel;

