import React from "react";
import '../../sass/footer.css';

import facebook from '../../img/icons/fb.svg';
import instagram from '../../img/icons/ig.svg';
import youTube from '../../img/icons/yt.svg';
import twitter from '../../img/icons/x.svg';
import tiktok from '../../img/icons/tt.svg';


const Footer = () => {

    return(
        <footer>
            <div className="footer-whiteUnderline"></div>
            <div className="footer-redUnderline"></div>
            <div className="socialsContainer">
                <div className="imgWrapper">
                    <a href="https://www.facebook.com/gladysz.maciej" target="_blank" rel="noopener noreferrer">
                        <img src={facebook} alt="Facebook"/>
                    </a>
                    <a href="https://www.instagram.com/maciejgladyszofficial" target="_blank" rel="noopener noreferrer">
                        <img src={instagram} alt="Instagram"/>
                    </a>
                    <a href="https://www.youtube.com/@maciejgladysz17" target="_blank" rel="noopener noreferrer">
                        <img src={youTube} alt="YouTube"/>
                    </a>
                    <a href="https://twitter.com/maciejgladysz17" target="_blank" rel="noopener noreferrer">
                        <img src={twitter} alt="Twitter/X"/>
                    </a>
                    <a href="https://www.tiktok.com/@maciejgladysz_official?lang=pl-PL" target="_blank" rel="noopener noreferrer">
                        <img src={tiktok} alt="TikTok"/>
                    </a>
                </div>
                <p>Copyright © 2024 by maciejgladysz.pl</p>
            </div>
        </footer>
    );
};

export default Footer;
