import React from "react";

import arrow from '../../img/icons/carouselArrow.svg';

const CustomArrow = ({ className, style, onClick, direction }) => {
    return (
        <div
            className={className}
            style={{ ...style, display: 'block', background: 'transparent' }}
            onClick={onClick}
        >
            {direction === 'left' ? (
                <img src={arrow} alt="Previous" />
            ) : (
                <img src={arrow} alt="Next" />
            )}
        </div>
    );
};


export default CustomArrow;