import React, {useState} from "react";
import '../sass/news.css';
import CustomH2 from "../components/technicalComponents/CustomH2";
import CustomArticle from "../components/technicalComponents/CustomArticle";
import article1 from '../img/articles/article1.webp';
import article2 from '../img/articles/article2.webp';
import article3 from '../img/articles/article3.webp';
import article4 from '../img/articles/article4.webp';
import article5 from '../img/articles/article5.webp';
import article6 from '../img/articles/article6.webp';
import article7 from '../img/articles/article7.webp';
import article8 from '../img/articles/article8.webp';
import article9 from "../img/articles/article9.webp";
import article11 from "../img/articles/article11.webp";
import article10 from "../img/articles/article10.webp";
import article12 from "../img/articles/article12.webp";
import article13 from "../img/articles/article13.webp";

import {useTranslation} from "react-i18next";
import CustomButton from "../components/technicalComponents/CustomButton";

const News = () => {
    const [showMore, setShowMore] = useState(false);
    const {t} = useTranslation();
    return(
        <div className="newsContainer">
            <CustomH2 text="News"/>
            <h3>{t("under_title")}</h3>
            <div className="newsContainerGrid">
                <CustomArticle
                    date="2024-07-28"
                    title={t("art_13_title")}
                    image={article13}
                    isLarge={true}
                    linkEN="https://mailchi.mp/0f00add48d4b/double-f4-victory-for-maciej-gadysz-in-aragon?e=9eec90125b"
                    linkPL="https://mailchi.mp/47d1093915d9/podwjne-zwycistwo-macieja-gadysza-w-f4-w-aragonii?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-07-25"
                    title={t("art_12_title")}
                    image={article12}
                    isLarge={false}
                    linkEN="https://mailchi.mp/d8e16e14620a/maciej-gadysz-aiming-high-in-f4-in-aragon?e=9eec90125b"
                    linkPL="https://mailchi.mp/7072457b9cd3/maciej-gadysz-po-kolejne-trofea-w-f4-w-aragonii?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-07-07"
                    title={t("art_11_title")}
                    image={article11}
                    isLarge={false}
                    linkEN="https://mailchi.mp/013a4a04bd09/maciej-gadysz-takes-first-formula-4-victory?e=9eec90125b"
                    linkPL="https://mailchi.mp/d16a597a4b8d/maciej-gadysz-wygrywa-w-formule-4?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-07-04"
                    title={t("art_10_title")}
                    image={article10}
                    isLarge={false}
                    linkEN="https://mailchi.mp/fb8773827424/maciej-gadysz-ready-to-race-on-another-f1-track?e=9eec90125b"
                    linkPL="https://mailchi.mp/c04b0556e190/maciej-gadysz-gotowy-do-walki-na-kolejnym-torze-f1?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-06-10"
                    title={t("art_09_title")}
                    image={article9}
                    isLarge={false}
                    linkEN="https://mailchi.mp/c52dc040e007/maciej-gadysz-takes-points-in-portimao?e=9eec90125b"
                    linkPL="https://mailchi.mp/951f0111dfc8/widowiskowa-pogo-i-punkty-gadysza-w-portimao?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-05-12"
                    title={t("art_08_title")}
                    image={article8}
                    isLarge={false}
                    linkEN="https://mailchi.mp/983e6652e8cd/maciej-gadysz-on-the-podium-in-spanish-f4-debut?e=9eec90125b"
                    linkPL="https://mailchi.mp/7a308ab7b672/maciej-gadysz-na-podium-w-debiucie-w-hiszpaskiej-f4?e=9eec90125b"
                />
                <CustomArticle
                    date="2024-05-09"
                    title={t("art_07_title")}
                    image={article7}
                    isLarge={false}
                    linkEN="https://mailchi.mp/a5c1a5b9f982/maciej-gadysz-starts-the-spanish-f4-season?e=9eec90125b"
                    linkPL="https://mailchi.mp/bff6887be3e5/maciej-gadysz-rozpoczyna-sezon-w-hiszpaskiej-formule-4?e=9eec90125b"
                />
                {showMore && (
                    <>
                        <CustomArticle
                            date="2024-04-14"
                            title={t("art_06_title")}
                            image={article6}
                            isLarge={false}
                            linkEN="https://mailchi.mp/d8e4144e58af/maciej-gadysz-to-race-for-poland-in-fia-motorsport-games?e=9eec90125b"
                            linkPL="https://mailchi.mp/f1a9c8ff585c/maciej-gadysz-reprezentantem-polski-na-3-igrzyskach-sportw-motorowych-fia-motorsport-games?e=9eec90125b"
                            isTextSmall={true}
                        />
                        <CustomArticle
                            date="2024-03-11"
                            title={t("art_01_title")}
                            image={article1}
                            isLarge={false}
                            linkEN="https://mailchi.mp/c625e019df11/maciej-gadysz-back-at-the-front-in-formula-12683241?e=9eec90125b"
                            linkPL="https://mailchi.mp/f47e9044d65c/maciej-gadysz-w-czowce-formuy-4-w-aragonii-12683240?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-03-03"
                            title={t("art_02_title")}
                            image={article2}
                            linkPL="https://mailchi.mp/a855544e1319/maciej-gadysz-w-czowce-formuy-4-w-aragonii?e=9eec90125b"
                            linkEN="https://mailchi.mp/715d9e4e64b6/maciej-gadysz-back-at-the-front-in-formula-4?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-02-18"
                            title={t("art_03_title")}
                            image={article3}
                            linkPL="https://mailchi.mp/54285bb2dda1/maciej-gadysz-ponownie-w-czowce-formuy-4?e=9eec90125b"
                            linkEN="https://mailchi.mp/c52e2784644d/maciej-gadysz-at-the-front-again-in-formula-4?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-02-11"
                            title={t("art_05_title")}
                            image={article5}
                            linkPL="https://mailchi.mp/825919836a0e/wymarzony-debiut-gadysza-w-formule-4?e=9eec90125b"
                            linkEN="https://mailchi.mp/c5b24d1fb5ae/perfect-f4-debut-for-maciej-gadysz?e=9eec90125b"
                        />
                        <CustomArticle
                            date="2024-02-07"
                            title={t("art_04_title")}
                            image={article4}
                            linkPL="https://mailchi.mp/c0f8a25386b3/maciej-gadysz-rozpoczyna-sezon-f4-w-jerez?e=9eec90125b"
                            linkEN="https://mailchi.mp/f36107b5628a/maciej-gadysz-ready-for-f4-debut-in-spain?e=9eec90125b"
                        />

                    </>
                )}
            </div>
            <div className="buttonContainer">
                <CustomButton label={showMore ? t("button_03") : t("button_02")} onClick={() => setShowMore(!showMore)}/>
            </div>

        </div>
    );
}

export default News;